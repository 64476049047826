exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mednomics-js": () => import("./../../../src/pages/mednomics.js" /* webpackChunkName: "component---src-pages-mednomics-js" */),
  "component---src-pages-medrisk-js": () => import("./../../../src/pages/medrisk.js" /* webpackChunkName: "component---src-pages-medrisk-js" */),
  "component---src-pages-medsignal-js": () => import("./../../../src/pages/medsignal.js" /* webpackChunkName: "component---src-pages-medsignal-js" */)
}

